import axios from 'axios';

import productsSection from "../../components/productsSection/index.vue";
import subHeroSection from "../../components/subHeroSection/index.vue";
import subscribeSection from "../../components/subscribeSection/index.vue";

export default {
  name: "productListPage",
  components: {
    productsSection,
    subHeroSection,
    subscribeSection
  },
  data() {
    return {
      products: []
    }
  },
  async created() {
    const result = await axios.get('https://erftpap-backend.herokuapp.com/api/products');
    this.products = result.data.data;
    this.products.map(product => product.images = product.images[0]);
  },
};
