import subHeroSection from "../../components/subHeroSection/index.vue";
import privacySection from "../../components/privacySection/index.vue";
import subscribeSection from "../../components/subscribeSection/index.vue";


export default {
  name: "privacyPage",
  components: {
    subHeroSection,
    privacySection,
    subscribeSection
  },
};
