import axios from 'axios';

export default {
  name: "subscribeSection",
  components: {
  },
  data() {
    return {
      model: {
        email: ''
      }
    };
  },
  created() {

  },

  methods: {
    async subscribe(event) {
      event.preventDefault();
      const result = await axios.post('https://erftpap-backend.herokuapp.com/api/subscribers', this.model);
      this.$toast.open({
        message: 'Email sent',
        type: 'success',
        // all of other options may go here
      });
      this.model.email = '';
    }
  },
};
