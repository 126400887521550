import Vue from 'vue'
import VueRouter from 'vue-router'
import Navbar from "../views/navbar/index.vue";
import Home from "../views/homePage/index.vue";
import About from "../views/aboutPage/index.vue";
import Contact from "../views/contactPage/index.vue";
import Products from "../views/productListPage/index.vue";
import Product from "../views/productDetailPage/index.vue";
import Privacy from "../views/privacyPage/index.vue";


Vue.use(VueRouter)


export const routes = [
  {
    path: "/",
    redirect: "Home",
    component: Navbar,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home
      },
      {
        path: '/about',
        name: 'About',
        component: About
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: "/products",
        name: "Products",
        component: Products
      },
      {
        path: "/contact",
        name: "Contact",
        component: Contact
      },
      {
        path: "/product/:id",
        name: "Product",
        component: Product
      },
      {
        path: "/privacy",
        name: "Privacy",
        component: Privacy
      },

    ]
  }
]
const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


