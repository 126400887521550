import productSection from "../../components/productSection/index.vue";
import subHeroSection from "../../components/subHeroSection/index.vue";
import subscribeSection from "../../components/subscribeSection/index.vue";

import axios from "axios";

export default {
  name: "productDetailPage",
  components: {
    productSection,
    subHeroSection,
    subscribeSection
  },
  data() {
    return {
      product: {}
    }
  },
  async created() {
    console.log(this.$route);
    const response = await axios.get(`https://erftpap-backend.herokuapp.com/api/products/${this.$route.params.id}`)
    this.product = response.data.data;
  },
};
