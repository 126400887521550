export default {
  name: "productsSection",
  components: {
  },

  props: {
    products: []
  },
  data() {
    return {
    };
  },
  created() {

  },

  methods: {

  },
};
