import axios from 'axios';

export default {
  name: "formSection",
  components: {
  },
  data() {
    return {
      model: {
        email: '',
        name: '',
        subject: '',
        message: '',
      }
    };
  },
  created() {

  },

  methods: {
    async contactUs(event) {
      event.preventDefault();
      const result = await axios.post('https://erftpap-backend.herokuapp.com/api/messages', this.model);
      this.$toast.open({
        message: 'Message sent',
        type: 'success',
        // all of other options may go here
      });
      this.model.email = '';
      this.model.subject = '';
      this.model.name = '';
      this.model.message = '';
    }
  },
};
