import contactSection from "../../components/contactSection/index.vue";
import subHeroSection from "../../components/subHeroSection/index.vue";
import subscribeSection from "../../components/subscribeSection/index.vue";

export default {
  name: "contactPage",
  components: {
    contactSection,
    subHeroSection,
    subscribeSection
  },
};
