import heroSection from "../../components/heroSection/index.vue";
import productsSection from "../../components/productsHomeSection/index.vue";
// import reviewSection from "../../components/reviewSection/index.vue";
import subscribeSection from "../../components/subscribeSection/index.vue";
import formSection from "../../components/formSection/index.vue";
import serviceSection from "../../components/serviceSection/index.vue";

export default {
  name: "homePage",
  components: {
    heroSection,
    productsSection,
    // reviewSection,
    serviceSection,
    formSection,
    subscribeSection
  },
};
