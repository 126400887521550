import subHeroSection from "../../components/subHeroSection/index.vue";
import aboutSection from "../../components/aboutSection/index.vue";
import reviewSection from "../../components/reviewSection/index.vue";
import subscribeSection from "../../components/subscribeSection/index.vue";
import serviceSection from "../../components/serviceSection/index.vue";

export default {
  name: "aboutPage",
  components: {
    subHeroSection,
    aboutSection,
    reviewSection,
    serviceSection,
    subscribeSection
  },
};
