import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "formSection",
  components: {
    Carousel,
    Slide
  },
  props: {
    product: {}
  },
  created() {
    
  },

  methods: {

  },
};
