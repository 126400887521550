export default {
  name: "subHeroSection",
  components: {
  },
  data() {
    return {
    };
  },
  created() {

  },
  methods: {
    heroTitle(name) {
      return `hero.${name.toLowerCase()}`;
    }
  },
};
