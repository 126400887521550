export default {
  name: "heroSection",
  components: {
  },
  data() {
    return {
    };
  },
  created() {

  },

  methods: {

  },
};
