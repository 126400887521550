import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "reviewSection",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
    };
  },
  created() {

  },

  methods: {

  },
};
