import axios from 'axios';
export default {
  name: "formSection",
  components: {
  },
  data() {
    return {
      model: {
        name: '',
        email: '',
        subject: '',
        telephone: '',
        organization: '',
        destinationPort: '',
        products: '',
        requestBefore: '2021-01-01'
      }
    };
  },
  created() {

  },

  methods: {
    async sendRequest(event) {
      event.preventDefault();
      const result = await axios.post('https://erftpap-backend.herokuapp.com/api/requests', this.model);
      this.$toast.open({
        message: 'Information sent',
        type: 'success',
        // all of other options may go here
      });
      this.model = {
        name: '',
        email: '',
        subject: '',
        telephone: '',
        organization: '',
        destinationPort: '',
        products: '',
        requestBefore: '',
      };
    }
  },
};
