import { routes } from '../../router';

export default {
  name: "navbarSection",
  data() {
    return {
      languageNavBarVerif: true
    };
  },
  created() {
    this.languageNavBar()
  },
  methods: {
    languageNavBar() {
      if (localStorage.getItem("lang") == "gr") {
        this.languageNavBarVerif = false
      } else {
        this.languageNavBarVerif = true
      }
    },
    changeLang(lang) {
      console.log(lang)
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang)
      this.languageNavBarVerif = !this.languageNavBarVerif
    },
    routes() {
      return routes[0].children
        .map(item => { return { name: item.name, path: item.path } })
        .splice(0, 4);
    },
    linkName(name) {
      return `navBar.${name.toLowerCase()}`;
    }
  },
};
