import { routes } from '../../router';

export default {
    name: 'footerSection',
    data() {
      return {
      };
    },
    methods: {
      routes() {
        return routes[0].children
          .map(item => { return { name: item.name, path: item.path }})
          .splice(0, 4);
      },
      linkName(name) {
        return `footer.${name.toLowerCase()}`;
      }
    },
  }
  